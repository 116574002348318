import {Typography} from '@material-ui/core';
import clsx from 'clsx';
import {cloneDeep, get} from 'lodash';
import {Fragment, useCallback, useMemo} from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {
  BuyerBadgeIcon,
  QuestionGreenIcon,
  SellerBadgeIcon,
} from '../../../constants/images';
import useTicketHelper from '../../../hooks/ticket/useTicketHelper';
import useDateHelper from '../../../hooks/utils/useDateHelper';
import {truncateAmount} from '../../../utils/saleHelper';
import ArrowLink from '../../Common/ArrowLink';
import HintTooltip from '../../Common/HintTooltip';
import StatusStateIndicator from '../../Common/Indicator/StatusStateIndicator';
import EventDate from '../../Events/EventDate';
import SellerMarks from '../../Sales/Details/SellerMark';
import {NoteEntity} from '../../../types/common/NoteEntity';

export default function useSaleListHelper() {
  const intl = useIntl();
  const dateHelper = useDateHelper();
  const ticketHelper = useTicketHelper();

  const printMemberCell = useCallback((obj, isSeller) => {
    if (!obj) {
      return null;
    }
    const {id, firstname, lastname} = obj ?? {};
    const link = `/admin-dashboard/users/${id}`;
    const name = [firstname ?? '', lastname || ''].join(' ');

    return (
      <div
        className={clsx('d-flex align-items-center')}
        onClick={e => e.stopPropagation()}
      >
        <span className="pe-1">
          {isSeller ? (
            <SellerBadgeIcon style={{fontSize: 15}} />
          ) : (
            <BuyerBadgeIcon style={{fontSize: 15}} />
          )}
        </span>
        <ArrowLink title={name} url={link} />
      </div>
    );
  }, []);

  const columnDefs = useMemo(() => {
    return [
      {
        name: 'id',
        label: intl.formatMessage({id: 'dashboard.sales.sales'}),
        valueProperty: 'renderedId',
        collapse: false,
        width: '120px',
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'saleMetadata.eventDate',
        label: intl.formatMessage({id: 'dashboard.sales.event'}),
        valueProperty: 'renderedEvent',
        collapse: true,
        width: '15%',
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'saleMetadata.competitionName',
        label: intl.formatMessage({id: 'dashboard.sales.competition_venue'}),
        valueProperty: 'renderedCompetition',
        collapse: true,
        width: '20%',
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'saleMetadata.buyerName',
        label: intl.formatMessage({id: 'dashboard.sales.buyer_seller'}),
        valueProperty: 'renderedUsers',
        collapse: true,
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'saleMetadata.ticketType',
        label: intl.formatMessage({id: 'dashboard.sales.type'}),
        valueProperty: 'renderedTicket',
        collapse: true,
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'totalWithCharges',
        label: intl.formatMessage({id: 'dashboard.sales.price'}),
        collapse: true,
        width: 90,
        valueProperty: 'renderedPrice',
        numeric: false,
        disablePadding: false,
      },
      {
        name: 'saleMetadata.saleStatus',
        label: intl.formatMessage({id: 'dashboard.sales.sales_payment_status'}),
        valueProperty: 'renderedStatus',
        collapse: false,
        numeric: false,
        disablePadding: false,
      },
    ];
  }, [intl]);

  const renderStatusValue = useCallback(sale => {
    let transactionStatus = get(sale, 'saleMetadata.transactionStatus');
    if (transactionStatus === 'CANCELED' || transactionStatus === 'canceled') {
      transactionStatus = 'refunded';
    } else if (transactionStatus === 'requires_capture') {
      transactionStatus = 'pending';
    } else if (transactionStatus === 'succeeded') {
      transactionStatus = 'paid';
    }

    return (
      <div className="d-flex flex-column">
        <StatusStateIndicator status={get(sale, 'saleMetadata.saleStatus')} />
        <StatusStateIndicator status={transactionStatus} />
        {sale.noPayout && <StatusStateIndicator status="on-pause" />}
      </div>
    );
  }, []);

  const mapTableSale = useCallback(
    sale => {
      const event = {
        date: get(sale, 'saleMetadata.eventDate'),
        postponed: get(sale, 'saleMetadata.eventPostponed'),
      };

      return {
        ...sale,
        renderedId: (
          <div className="d-flex flex-column ps-2">
            <div className="d-flex align-items-center mb-1">
              <span className="me-1">
                #{get(sale, 'saleMetadata.referenceHexa')}
              </span>
              {Array.isArray(sale.notes) && sale.notes.length > 0 && (
                <HintTooltip
                  id={sale.id}
                  gray={false}
                  icon={<QuestionGreenIcon style={{fontSize: 12}} />}
                  content={
                    <div className="card border-0 p-0">
                      <div className="card-header border-0 bg-white p-0 pb-1">
                        <Typography variant="subtitle2" color="textSecondary">
                          {intl.formatMessage({id: 'actions.notes'})}
                        </Typography>
                      </div>
                      <div className="card-body p-0">
                        <Typography variant="subtitle2">
                          {sale.notes.map((note: NoteEntity, i) => (
                            <Fragment key={i}>
                              <Typography
                                variant="subtitle2"
                                component="span"
                                style={{fontWeight: 700}}
                              >
                                <FormattedMessage id="actions.note" /> {i + 1}
                                :&nbsp;
                              </Typography>
                              {note.note}
                              {i < sale.notes.length - 1 && (
                                <Typography
                                  variant="subtitle2"
                                  color="primary"
                                  component="span"
                                  style={{fontWeight: 700}}
                                >
                                  &nbsp;/&nbsp;
                                </Typography>
                              )}
                            </Fragment>
                          ))}
                        </Typography>
                      </div>
                    </div>
                  }
                />
              )}
            </div>
            <span className="text-muted text-nowrap">
              {dateHelper.formatDate(sale.createdAt)}
            </span>
            <span className="text-muted text-nowrap">
              {dateHelper.format(sale.createdAt, 'HH:mm')}
            </span>
          </div>
        ),
        renderedEvent: (
          <div className="d-flex flex-column">
            <span className="mb-1">{get(sale, 'saleMetadata.eventName')}</span>
            <p className="text-muted mb-0">
              <EventDate event={event} />
            </p>
            {!event?.postponed && (
              <p className="text-muted mb-lg-0">
                <EventDate event={event} dateFormat="HH:mm" />
              </p>
            )}
          </div>
        ),
        renderedCompetition: (
          <div className="d-flex flex-column">
            <span className="mb-1">
              {get(sale, 'saleMetadata.competitionName')}
            </span>
            <span className="text-muted">
              {get(sale, 'saleMetadata.venueEntitled')}
            </span>
          </div>
        ),
        renderedUsers: (
          <div className="d-flex flex-column">
            <span className="mb-1">{printMemberCell(sale.seller, true)}</span>
            {printMemberCell(sale.buyer)}
          </div>
        ),
        renderedTicket: (
          <div className="d-flex flex-column">
            <span className="mb-1">
              <FormattedMessage
                id={`ticket.type.${get(sale, 'saleMetadata.ticketType')}`}
              />{' '}
              ({sale.units})
            </span>
            <SellerMarks
              iconSize={15}
              items={ticketHelper.getSellerMarks(sale.seller)}
            />
          </div>
        ),
        renderedPrice: (
          <FormattedNumber
            value={truncateAmount(sale.totalWithCharges, 2)}
            style="currency"
            currency="GBP"
          />
        ),
        renderedStatus: renderStatusValue(sale),
      };
    },
    [dateHelper, intl, printMemberCell, renderStatusValue, ticketHelper]
  );

  const parseFilterValues = useCallback((filterValues = {}) => {
    const res = cloneDeep(filterValues);

    if (res['paymentStatus']) {
      res['paymentStatus'] = res['paymentStatus'].reduce((c, v) => {
        c.push(v);
        if (['refunded'].includes(v)) {
          c.push('CANCELED', 'canceled');
        } else if (v === 'pending') {
          c.push('requires_capture');
        } else if (v === 'paid') {
          c.push('succeeded');
        }

        return c;
      }, []);
    }

    if (res.optionType && Array.isArray(res.optionType)) {
      for (const optionType of res.optionType) {
        res[`seller.${optionType}`] = true;
      }
      delete res.optionType;
    }

    if (res.hasNote) {
      res['note[exists]'] = res.hasNote;
      delete res.hasNote;
    }

    return res;
  }, []);

  const parseParams = useCallback((params, {timestamp = false} = {}) => {
    const parsedParams = cloneDeep(params);

    if (params.sort && params.sort.by === 'saleMetadata.buyerName') {
      parsedParams.sort = [
        {by: 'buyer.firstname', direction: params.sort.direction},
        {by: 'buyer.lastname', direction: params.sort.direction},
      ];
    }

    if (timestamp) {
      parsedParams['timestamp'] = new Date().getTime();
    }

    return parsedParams;
  }, []);

  const res = useMemo(
    () => ({
      columnDefs,
      mapTableSale,
      parseParams,
      parseFilterValues,
    }),
    [columnDefs, mapTableSale, parseParams, parseFilterValues]
  );

  return res;
}
