import React from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {AutocompleteProps} from '@material-ui/lab/Autocomplete/Autocomplete';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';

const useAutocompleteStyles = makeStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.paper,
      height: theme.spacing(3.5),
      fontSize: 14,
      paddingTop: '1px !important',
    },
  },
  option: {
    fontSize: 14,
  },
  clearIndicator: {
    '& .MuiSvgIcon-root': {
      fontSize: 17,
    },
  },
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    // default border
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.border}`,
      top: 0,
    },
    // hover border
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.border}`,
    },
    // focused border
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.border}`,
    },
  },
}));

interface AppAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  label?: string;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: AppAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const {label, placeholder, textFieldProps, ...others} = props;
  const rootClasses = useAutocompleteStyles();
  const inputClasses = useInputStyles();

  return (
    <MuiAutocomplete
      classes={rootClasses}
      popupIcon={<ExpandMoreIcon fontSize="small" />}
      {...others}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          classes={inputClasses}
          size="small"
          placeholder={placeholder}
          variant="outlined"
          {...textFieldProps}
          // override inputProps autoComplete prop to set autocomplete to "new-username"
          // autocomplete off doesn't always works
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-username',
          }}
        />
      )}
    />
  );
}

export default Autocomplete;
