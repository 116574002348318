import React, {useRef, useMemo, useEffect, useState, useCallback} from 'react';
import {injectIntl} from 'react-intl';
import {Box} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import DataTable from '../Widgets/DataTable';
import usePayoutList from './usePayoutList';
import IconButton from '../Common/IconButton';
import {FILTER_ICON, RefreshSearchResultsIcon} from '../../constants/images';
import * as actionTypes from '../../constants/actionTypes';
import {
  ADMIN_PAUSE_RESUME_PAYOUT,
  ADMIN_TRANSACTION_RESUME_PAUSE_PAYOUT,
} from '../../constants/permissions';
import KeywordSearchForm, {KEYWORD_FIELD} from '../Form/KeywordSearchForm';
import Filters from '../Widgets/Filters';
import usePermission from '../../Auth/usePermission';
import ActiveFilters from '../Widgets/ActiveFilters';

/* Transactions List - Payout */
const PayoutList = ({intl}) => {
  const {hasAnyPermission, hasPermissions} = usePermission();
  const containerRef = useRef();
  const options = useMemo(() => ({version: 'v2'}), []);
  const {
    rows,
    loading,
    headerCells,
    renderSubtable,
    totalItems,
    filterOpened,
    setFilterOpened,
    activeFiltersCount,
    filterItems,
    containerWidth,
    getResults,
    tableState,
  } = usePayoutList(containerRef, options);
  const dispatch = useDispatch();
  const handleRowClick = useCallback(
    itemId => console.info('show details ?', itemId),
    []
  );

  const onPauseStartClick = useCallback(
    row => {
      const {id, rowIsPaused, rowIsStarted} = row;
      if (rowIsPaused) {
        //Resume Payout
        dispatch({
          type: actionTypes.API_ADMIN_RESUME_PAYOUT_REQUESTED,
          payload: {
            id,
          },
        });
      } else if (rowIsStarted) {
        //Pause Payout
        dispatch({
          type: actionTypes.API_ADMIN_PAUSE_PAYOUT_REQUESTED,
          payload: {
            id,
          },
        });
      }
    },
    [dispatch]
  );

  const onRetryClick = useCallback(
    row => {
      const {id} = row;
      // Retry Payout
      dispatch({
        type: actionTypes.API_ADMIN_RETRY_PAYOUT_REQUESTED,
        payload: {
          id,
        },
      });
    },
    [dispatch]
  );

  const [scrollElement, setScrollElement] = useState(
    window.document.querySelector('.dashboard-wrapper')
  );
  useEffect(() => {
    const id = setInterval(() => {
      const elem = window.document.querySelector('.dashboard-wrapper');
      if (elem) {
        setScrollElement(elem);
        clearInterval(id);
      }
    }, 100);
    return () => {
      clearInterval(id);
    };
  }, []);

  const headCells = useMemo(
    () =>
      headerCells.map(item => ({
        ...item,
        numeric: false,
        disablePadding: false,
      })),
    [headerCells]
  );

  const windowScrollerProps = useMemo(
    () => ({
      ...(scrollElement ? {scrollElement} : {}),
    }),
    [scrollElement]
  );

  const handleFilterClose = useCallback(() => setFilterOpened(false), []);

  const [activeFilters, setActiveFilters] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setIsRefreshing(true);
    getResults(null);

    setTimeout(() => {
      if (!loading) {
        setIsRefreshing(false);
      }
    }, 500);
  }, [getResults]);

  const onSearchParamsChange = useCallback(
    (
      activeFiltersForDisplay: {id: string; title: any; values: any}[],
      applied: boolean,
      cleared: boolean
    ) => {
      // Set active filters to pass to ActiveFilters component
      // Do this when any change to searchParams is made,
      // so we're not only listening to the applyFilters action
      if (applied && !cleared) {
        // Set active filters that have been applied to the search query
        setActiveFilters(activeFiltersForDisplay);
      } else if (cleared) {
        // Clear active filters
        setActiveFilters([]);
      }
    },
    []
  );

  return (
    <>
      <Filters
        form="PayoutListFilterForm"
        opened={filterOpened}
        translations={{
          filter: intl.formatMessage({id: 'actions.filter'}),
          close: intl.formatMessage({id: 'actions.close'}),
          clear: intl.formatMessage({id: 'actions.clear'}),
          apply: intl.formatMessage({id: 'actions.apply'}),
        }}
        items={filterItems}
        onClose={handleFilterClose}
        onFilter={(v, p) => {
          getResults(p);
          setFilterOpened(false);
        }}
        onSearchParamsChange={onSearchParamsChange}
        onClear={onRefresh}
        filterAdditionalParams={{page: 1}}
        clearAdditionalParams={{[KEYWORD_FIELD]: '', page: 1}}
      />
      <div className="row align-items-center justify-content-between search-row">
        <div className="col-xl-4 col-lg-8 d-flex">
          {/* Search */}
          <KeywordSearchForm
            updateUrlOnChange
            additionalParams={{page: 1}}
            onSearch={(v, p) => getResults(p)}
          />
          {/* Refresh button */}
          <Box ml={1} mt={1}>
            <IconButton
              variant="filled"
              size="xs"
              onClick={onRefresh}
              style={{
                transform: isRefreshing ? 'rotate(360deg)' : 'none',
                transition: 'transform 0.5s',
              }}
            >
              <RefreshSearchResultsIcon style={{width: 18, height: 14}} />
            </IconButton>
          </Box>
          {/* Filter Button on mobile view only */}
          <Box ml={1} className="d-block d-lg-none">
            <IconButton
              className="m-0"
              onClick={() => setFilterOpened(true)}
              {...(activeFiltersCount ? {numberLabel: activeFiltersCount} : {})}
            >
              {FILTER_ICON}
            </IconButton>
          </Box>
        </div>
        {/* Button toolbar */}
        <div className="col-auto d-none d-lg-block">
          {/* Filter button */}
          <IconButton
            className="m-0"
            onClick={() => setFilterOpened(true)}
            {...(activeFiltersCount ? {numberLabel: activeFiltersCount} : {})}
          >
            {FILTER_ICON}
          </IconButton>
        </div>
      </div>

      <div
        id="cont"
        ref={containerRef}
        style={{maxWidth: '100%', overflow: 'hidden'}}
      >
        <ActiveFilters items={activeFilters} onFilterRemoved={onRefresh} />
        <DataTable
          wrappedRow
          canPauseStart={hasAnyPermission(
            ADMIN_TRANSACTION_RESUME_PAUSE_PAYOUT
          )}
          canRetry={hasAnyPermission(ADMIN_TRANSACTION_RESUME_PAUSE_PAYOUT)}
          additionalContent
          className="transaction-payout-list"
          canSelect={false}
          empty={!loading && totalItems === 0}
          loading={loading}
          onRowClick={handleRowClick}
          headCells={headCells}
          rows={rows}
          renderSubRows={renderSubtable}
          total={totalItems ?? 0}
          clickableRow={false}
          handleParameterChange={tableState.handleDataTableParamChange}
          defaultOrder={tableState.orderDirection.toLowerCase()}
          defaultOrderBy={tableState.orderBy}
          defaultRowPerPage={tableState.pageSize}
          currentPage={tableState.page}
          // for virtual table
          virtualTableWidth={containerWidth}
          virtualRowHeight={72 + 8}
          virtualHeaderHeight={52}
          windowScrollerProps={windowScrollerProps}
          virtualWrappedRowHeight={33}
          virtualWrappedRowAdditionalHeight={63 + 7}
          //Show pauseStart button conditionally
          {...(hasPermissions(ADMIN_PAUSE_RESUME_PAYOUT)
            ? {onPauseStartClick: onPauseStartClick}
            : {})}
          //Show retry button conditionally
          {...(hasPermissions(ADMIN_PAUSE_RESUME_PAYOUT)
            ? {onRetryClick: onRetryClick}
            : {})}
        />
      </div>
    </>
  );
};

export default injectIntl(PayoutList);
