import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {Typography, CircularProgress, Box} from '@material-ui/core';
import {useParams, useRouteMatch, useHistory} from 'react-router-dom';
import UploadableProfilePicture from './Common/UploadableProfilePicture';
import StateIndicator from '../Common/StateIndicator';
import TabWidget from '../Widgets/TabWidget';
import ProfileDetailsTab from './ProfileDetailsTab';
import AddressDetailsTab from './AddressDetailsTab';
import FinanceDetailsTab from './FinanceDetailsTab';
import Dropdown from '../Common/Dropdown';
import useFetchUser from '../../hooks/user/useFetechUser';
import ConfirmationPopup from '../Sales/Details/Popups/ConfirmationPopup';
import {useDispatch} from 'react-redux';
import * as actionTypes from '../../constants/actionTypes';
import * as urls from '../../constants/urls';
import PermissionContainer from '../Common/PermissionContainer';
import {userIdToHexadecimal} from '../../utils/hexadecimalConverter';
import usePermission from '../../Auth/usePermission';

/**
 * User Details
 */
let UserDetails = ({intl}) => {
  const {id} = useParams();
  const pathMatch = useRouteMatch();
  const path = pathMatch.path.replace(':id', id);
  const dispatch = useDispatch();
  const history = useHistory();
  // get user datas from redux store
  const {loaded, user, getUserData} = useFetchUser(id);
  // Suspend popup
  const [suspendPopup, setSuspendPopup] = useState(false);
  // Unsuspend popup
  const [unsuspendPopup, setUnSuspendPopup] = useState(false);

  // Delete popup
  const [deletePopup, setDeletePopup] = useState(false);

  // Dispatch action to fetch user details
  useEffect(() => {
    getUserData();
  }, []);

  // Suspend user
  const suspendUser = () => {
    setSuspendPopup(false);
    dispatch({
      type: actionTypes.API_ADMIN_SUSPEND_USER_REQUESTED,
      payload: {userId: user.id},
    });
  };

  // Unsuspend user
  const unSuspendUser = () => {
    setUnSuspendPopup(false);
    dispatch({
      type: actionTypes.API_ADMIN_UNSUSPEND_USER_REQUESTED,
      payload: {userId: user.id},
    });
  };

  // Resend Guest Email Action
  const resendGuestEmail = () => {
    dispatch({
      type: actionTypes.API_ADMIN_SEND_GUEST_EMAIL_REQUESTED,
      payload: {userId: user.id},
    });
  };

  // Send Reset Password Email
  const sendResetPasswordEmail = () => {
    dispatch({
      type: actionTypes.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_REQUESTED,
      payload: {userId: user.id},
    });
  };

  // navigate back to user list
  const navigateCallback = () => history.push(urls.USERS_LIST_PATH);

  // Delete User Action
  const deleteUserAction = () => {
    setDeletePopup(false);
    dispatch({
      type: actionTypes.API_ADMIN_DELETE_USER_REQUESTED,
      payload: {userId: user.id, callback: navigateCallback},
    });
  };

  const impersonateUser = () => {
    window.location.href =
      process.env.REACT_APP_API_BASE_URL + `/?_switch_user=${user.username}`;
  };

  // open suspend modal confirmation
  const openSuspendPopup = () => setSuspendPopup(true);

  // open unsuspend modal confirmation
  const openUnSuspendPopup = () => setUnSuspendPopup(true);

  // open delete user modal confirmation
  const openDeletUserPopup = () => setDeletePopup(true);
  const {hasAnyPermission} = usePermission();

  if (!user) {
    return (
      <Box
        height="100vh"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/*<CircularProgress />*/}
      </Box>
    );
  }

  let permissions = [
    {
      label: intl.formatMessage({
        id: 'dashboard.user_details.actions.reset_password',
      }),
      onClick: sendResetPasswordEmail,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.user_details.actions.resend_guest_email',
      }),
      onClick: resendGuestEmail,
    },
    user.enabled
      ? {
          label: intl.formatMessage({
            id: 'dashboard.user_details.actions.suspend_user',
          }),
          onClick: openSuspendPopup,
        }
      : {
          label: intl.formatMessage({
            id: 'dashboard.user_details.actions.unsuspend_user',
          }),
          onClick: openUnSuspendPopup,
        },
    {
      label: intl.formatMessage({
        id: 'dashboard.user_details.actions.delete_user',
      }),
      onClick: openDeletUserPopup,
    },
  ];

  if (
    hasAnyPermission(['admin_impersonate_user']) &&
    !user.roles?.includes('ROLE_ADMIN') &&
    !user.roles?.includes('ROLE_SUPER_ADMIN')
  ) {
    permissions = [
      {
        label: intl.formatMessage({
          id: 'dashboard.user_details.actions.impersonate',
        }),
        onClick: impersonateUser,
      },
      ...permissions,
    ];
  }

  return (
    <div className="details-page user">
      {/* Suspend User confirmation popup */}
      <ConfirmationPopup
        open={suspendPopup}
        setOpen={setSuspendPopup}
        onAccept={suspendUser}
        description={intl.formatMessage({
          id: 'dashboard.user_details.actions.suspend_user_message',
        })}
      />
      {/* Unsuspend User confirmation popup */}
      <ConfirmationPopup
        open={unsuspendPopup}
        setOpen={setUnSuspendPopup}
        onAccept={unSuspendUser}
        description={intl.formatMessage({
          id: 'dashboard.user_details.actions.unsuspend_user_message',
        })}
      />
      {/* Delete User confirmation popup */}
      <ConfirmationPopup
        open={deletePopup}
        setOpen={setDeletePopup}
        onAccept={deleteUserAction}
        description={intl.formatMessage({
          id: 'dashboard.user_details.actions.delete_user_message',
        })}
      />
      {/*Top header*/}
      <div className="container-fluid top-block bg-white border-bottom-0 shadow-none">
        <div className="details-header">
          <div className="row title-block">
            <div className="col-lg-6">
              <div className="details-header-part d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-center justify-content-lg-start">
                <div className="editable-avatar">
                  <UploadableProfilePicture user={user} canNotEdit />
                </div>
                <div className="information-part">
                  <Typography
                    variant="h3"
                    className="font-weight-bolder mt-0"
                  >{`${user.firstname} ${user.lastname}`}</Typography>
                  <div className="subelements d-flex align-items-center">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className="uid"
                    >
                      {userIdToHexadecimal(user.id)}
                    </Typography>
                    <StateIndicator
                      label={user.enabled ? 'Active' : 'Suspended'}
                      status={user.enabled ? 'active' : 'inactive'}
                      color={user.enabled ? 'green' : 'grey'}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Actions */}
            <PermissionContainer
              permissions={['admin_suspend_user', 'admin_delete_user']}
            >
              <div className="col-lg-6">
                <div className="d-flex justify-content-center justify-content-lg-end action-buttons">
                  <Dropdown
                    title={intl.formatMessage({id: 'actions.actions'})}
                    items={permissions}
                  />
                </div>
              </div>
            </PermissionContainer>
          </div>
        </div>
      </div>
      <TabWidget
        containerClass="container-fluid top-block bg-white"
        wrapperClass="details-header pt-0"
        tabs={[
          {
            label: intl.formatMessage({id: 'dashboard.user_details.profile'}),
            value: `${path}`,
            content: <ProfileDetailsTab user={user} />,
          },
          {
            label: intl.formatMessage({id: 'dashboard.user_details.address'}),
            value: `${path}/address`,
            content: <AddressDetailsTab user={user} />,
          },
          {
            label: intl.formatMessage({id: 'dashboard.user_details.finance'}),
            value: `${path}/finance`,
            content: <FinanceDetailsTab user={user} />,
          },
        ]}
      />
    </div>
  );
};

//Inject Intl translations into the component
UserDetails = injectIntl(UserDetails);

export default UserDetails;
