import React, {MouseEventHandler} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import {IconButtonProps} from '@material-ui/core/IconButton/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0.5),
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
    padding: 7,
    transition: 'background 300ms, color 300s',
    filter: 'brightness(100%)',

    '&:focus': {
      outline: 'none',
    },

    //fill variant default
    '&.default': {
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        '& svg path': {
          fill: theme.palette.secondary.contrastText,
        },
      },
    },
    //fill variant filled
    '&.filled': {
      '&:hover': {
        backgroundColor: 'inherit',
        filter: 'brightness(90%)',
      },
    },
    //fill variant action
    '&.action': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'inherit',
        filter: 'none',
        '& svg > *': {
          fill: '#21B59B',
        },
      },
    },

    '& .number-label-item': {
      fontFamily: 'Averta Semibold',
      padding: 6,
      backgroundColor: theme.palette.success.main,
      color: theme.palette.background.paper,
      fontSize: 14,
      lineHeight: 1.5,
      borderRadius: 10,
      marginLeft: 10,
      marginRight: -3,
      height: 32,
      minWidth: 32,
      textAlign: 'center',
    },

    [theme.breakpoints.down('md')]: {
      borderRadius: '50%',
      boxShadow: theme.shadows[2],
      width: 42,
      height: 42,
    },
    //size variant small
    '&.small': {
      width: 'auto',
      height: 'auto',
      minWidth: 'unset',
      padding: 0,
      boxShadow: theme.shadows[0],
      '& > svg': {
        width: 25,
        height: 25,
      },
    },
    '&.extra-small': {
      width: 'auto',
      height: 'auto',
      padding: 0,
      minWidth: 'unset',
      boxShadow: theme.shadows[0],
      transition: 'none',
      margin: 0,
      backgroundColor: 'transparent',
      '& > svg': {
        width: 16,
        height: 16,
        margin: 'auto',
      },
    },
    '&.dt-btn': {
      color: '#282B3E !important',
    },
  },
  label: {
    '& > svg': {
      width: 'auto',
      margin: 'auto',
    },
  },
}));

export type CustomIconButtonProps = IconButtonProps & {
  children?: React.ReactNode;
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'small' | 'medium';
  variant?: 'filled' | 'default' | 'action';
  numberLabel?: number;
  className?: string;
  onHover?: MouseEventHandler;
  onLeave?: MouseEventHandler;
};

/* Icon button */
const CustomIconButton = (props: CustomIconButtonProps) => {
  const {
    children,
    disabled = false,
    size = 'md',
    variant = 'default',
    numberLabel,
    className,
    onClick,
    onHover,
    onLeave,
    ...others
  } = props;
  const classes = useStyles();

  return (
    <IconButton
      color="secondary"
      classes={{
        root: clsx(
          classes.root,
          // @ts-ignore
          size === 'sm' && 'small',
          // @ts-ignore
          size === 'xs' && 'extra-small',
          variant,
          numberLabel && 'with-number-label'
        ),
        label: classes.label,
      }}
      disabled={disabled}
      className={clsx(className && className)}
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      {...others}
    >
      {children}
      {numberLabel && (
        <span className="number-label-item d-none d-lg-block">
          {numberLabel}
        </span>
      )}
    </IconButton>
  );
};

export default CustomIconButton;
