import React from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {AutocompleteProps} from '@material-ui/lab/Autocomplete/Autocomplete';
import {Value} from '@material-ui/lab/useAutocomplete';

const useAutocompleteStyles = makeStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.paper,
      height: theme.spacing(3.5),
      fontSize: 14,
      paddingTop: '1px !important',
    },
  },
  option: {
    fontSize: 14,
  },
  clearIndicator: {
    '& .MuiSvgIcon-root': {
      fontSize: 17,
    },
  },
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    // default border
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.border}`,
      top: 0,
    },
    // hover border
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.border}`,
    },
    // focused border
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.border}`,
    },
  },
}));

interface AppAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  label?: string;
  placeholder?: string;
  changeHandler: (
    value: Value<T, Multiple, DisableClearable, FreeSolo>
  ) => void;
  error?: string;
}

export function CustomAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: AppAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const {label, placeholder, changeHandler, error, ...others} = props;
  const rootClasses = useAutocompleteStyles();
  const inputClasses = useInputStyles();

  const handleChange = (
    event: React.ChangeEvent<{}>,
    value: Value<T, Multiple, DisableClearable, FreeSolo>
  ) => {
    changeHandler(value);
  };

  return (
    <>
      <MuiAutocomplete
        classes={rootClasses}
        popupIcon={<ExpandMoreIcon fontSize="small" />}
        onChange={handleChange}
        {...others}
        renderInput={params => (
          <TextField
            {...params}
            classes={inputClasses}
            size="small"
            placeholder={placeholder}
            variant="outlined"
          />
        )}
      />
      {error && <span className="error">{error}</span>}
    </>
  );
}

export default CustomAutocomplete;
