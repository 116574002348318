/* M-ticket */
import {useIntl} from 'react-intl';
import useDateHelper from '../../../hooks/utils/useDateHelper';
import {HoldingTimeIcon, MticketIcon} from '../../../constants/images';
import Typography from '@material-ui/core/Typography';
import StatusStateIndicator from '../../Common/Indicator/StatusStateIndicator';
import get from 'lodash.get';
import clsx from 'clsx';
import PermissionContainer from '../../Common/PermissionContainer';
import {ETICKET_MTICKET_FILES_PERMISSIONS} from '../../../constants/permissions';
import AttachmentPreviewRow from './AttachmentPreviewRow';
import {getSaleStatus} from '../../../utils/saleHelper';
import useSaleUtils from '../../../hooks/sale/useSaleUtils';

const MticketBlock = ({saleDetails, custom}) => {
  const intl = useIntl();
  const {
    sale,
    showDeliverTimeLeft,
    deliverTimeLeft,
    isDelivered: delivered,
  } = saleDetails;
  const dateHelper = useDateHelper();
  const {getMticket} = useSaleUtils();

  return (
    <div className="card details-card sales-details mx-0 mt-3">
      {/*Header*/}
      <div className="card-header bg-light-blue">
        <div className="row gx-2 align-items-center">
          <div className="col-auto">
            <span className="icon-wrapper blue">
              <MticketIcon style={{fontSize: 15}} />
            </span>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <Typography variant="body2" component="b">
                {intl.formatMessage({
                  id: 'dashboard.sales.details.mticket_delivery',
                })}
              </Typography>
              {/* If the sale status is delivered, and the status is set by the admin, display "Delivered by the admin" instead of "Delivered" */}
              <StatusStateIndicator
                status={
                  sale.status == 'delivered' &&
                  sale.status == sale.statusSetByAdmin
                    ? 'delivered-by-admin'
                    : getSaleStatus(sale)
                }
                className="ps-2"
                color="gray"
              />
            </div>
            {delivered && (
              <div className="row gx-2">
                <div className="col-auto col-lg-6">
                  {sale && sale.deliveryDate && (
                    <Typography variant="body2">
                      {custom ? (
                        <span>
                          {intl.formatMessage({
                            id: 'dashboard.sales.details.date_transferred',
                          })}
                        </span>
                      ) : (
                        <span>
                          {intl.formatMessage({
                            id: 'dashboard.sales.details.date_uploaded',
                          })}
                        </span>
                      )}
                      <span>
                        :{' '}
                        {dateHelper.format(
                          sale.deliveryDate,
                          'DD/MM/YYYY HH:mm'
                        )}
                      </span>
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*Content*/}
      {/*If no file to upload for custom delivery mobile then do not show this part*/}
      {custom && get(sale, 'mticketProofs', []).length === 0 ? null : (
        <div className="card-body">
          {/*Proof of transfer*/}
          <div
            className={clsx({
              'border-bottom pb-2 me-lg-5': showDeliverTimeLeft,
            })}
          >
            <div className="row align-items-center mb-1">
              <PermissionContainer
                permissions={ETICKET_MTICKET_FILES_PERMISSIONS}
              >
                <div className="col-auto col-lg-5">
                  <Typography variant="body2">
                    <b>
                      {intl.formatMessage({
                        id: 'dashboard.sales.details.proof_transfer',
                      })}
                    </b>
                  </Typography>
                </div>
              </PermissionContainer>
              {showDeliverTimeLeft && (
                <div className="col">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <HoldingTimeIcon />
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="ps-2"
                      >
                        {intl.formatMessage({
                          id: 'dashboard.sales.details.delivery_time_left',
                        })}
                      </Typography>
                    </div>
                    <Typography variant="subtitle2">
                      {deliverTimeLeft} left
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>
          {!delivered && (
            <Typography variant="body2" className="pt-2">
              {intl.formatMessage({
                id: 'dashboard.sales.details.mticket_not_delivered',
              })}
            </Typography>
          )}
          {delivered && get(sale, 'mticketProofs', []).length === 0 && (
            <Typography variant="body2" className="pt-2">
              {intl.formatMessage({
                id: 'dashboard.sales.details.no_files_uploaded_yet',
              })}
            </Typography>
          )}
          <PermissionContainer permissions={ETICKET_MTICKET_FILES_PERMISSIONS}>
            {delivered &&
              get(sale, 'mticketProofs', []).length > 0 &&
              get(sale, 'mticketProofs', []).map(mticketProof => (
                <AttachmentPreviewRow
                  key={mticketProof.id}
                  label={intl.formatMessage({
                    id: 'dashboard.sales.details.mticket',
                  })}
                  fileName={mticketProof.name}
                  onPreviewClick={() => getMticket(mticketProof.id)}
                  onDownloadClick={() => getMticket(mticketProof.id, true)}
                />
              ))}
          </PermissionContainer>
        </div>
      )}
    </div>
  );
};

export default MticketBlock;
